import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountingApiData } from '@app/models';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CALENDAR_SELECT } from '../models/Calendar';

@Injectable({ providedIn: 'root' })
export class DataService {
    constructor(private http: HttpClient) {}

    public getKpiData(
        id: string,
        range?: any,
        type?: string,
        appMobile = true
    ): Observable<CountingApiData> {
        const mappedType = {
            day: 'day',
            month: 'month',
            week: 'day',
            year: 'month',
            custom: 'day',
        };

        range.type = mappedType[type as never];
        return this.http
            .get<CountingApiData>(
                `${environment.backendDomain}/dashboardV2/kpi/business/${id}?appMobile=${appMobile}`,
                { params: range as never }
            )
            .pipe(
                map((value: any) => {
                    const newObject: any = {};
                    delete Object.assign(newObject, value, {
                        ['current']: value['curent'],
                    })['curent'];
                    return newObject;
                })
            );
    }

    public getCountingDatasByType(
        businessId: string,
        range: any,
        type: string,
        appMobile = true
    ) {
        const mappedType = {
            day: 'hour',
            month: 'day',
            week: 'day',
            year: 'month',
            custom: 'day',
        };
        return this.http.get<{
            current: {
                ca: number[];
                entry: number[];
                label: string[];
                ticket: number[];
            };
        }>(
            `${
                environment.backendDomain
            }/dashboardV2/charts/localbusiness/${businessId}/${
                mappedType[type as never]
            }?appMobile=${appMobile}`,
            { params: range } as never
        );
    }

    public getFrequencies(businessId: string, range: any) {
        return this.http.get(
            `${environment.backendDomain}/localbusinesses/${businessId}/customers/allfrequencies`,
            { params: range } as never
        );
    }

    public getJourney(businessId: string, range: any) {
        return this.http.get(
            `${environment.backendDomain}/dashboardV2/journey/business/${businessId}`,
            { params: range } as never
        );
    }

    public getBoxesByBusiness(id: string | number): Observable<any[]> {
        return this.http.get<any[]>(
            `${environment.backendDomain}/localbusinesses/${id}/boxes`
        );
    }
}
